<template>
  <div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <label>Group Name</label>
        <input class="form-control" v-model="groupData.groupName" type="text" placeholder="Group Name"/>
      </div>
      <div class="col-1"></div>
      <div class="col" style="padding-top: 25px;">
        <h3>{{ groupData.groupName }}</h3>
        <h5 v-if="groupData.createdOn">Creation Date: {{ groupData.createdOn }}</h5>
        <h5 v-if="groupData.lastUpdate">Last Updated: {{ groupData.lastUpdate }}</h5>
      </div>
      <div class="col-2"></div>

    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <label>Filter Role:</label>
        <JobTypeDropdown @roleHierarchy="changeRoleFilter($event)"
                         :job-id="selectedRoleType"
                         :show-esucc="showEsucc"
                         :show-esu-admin="showEsuAdmin"
                         :show-district-admin="showDistrictAdmin"
                         :show-instructor="showInstructor"
                         :is-disabled="false"
                         :show-nde="showNde"
        />
      </div>
      <div class="col-1"></div>

      <div class="col"></div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <label>Filter (not in group)</label>
        <input class="form-control" v-model="notInGroupFilter" placeholder="Name" />
      </div>
      <div class="col-1"></div>

      <div class="col">
        <label>Filter (in group)</label>
        <input class="form-control" v-model="inGroupFilter" placeholder="Name" />
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <label>Users not in group
          <b-tooltip target="_self" title="Sort by Last Name">
            <button @click="reverseNotInGroup" :class="notInGroupReversed ? 'bi bi-sort-up' : 'bi bi-sort-down'"
                    alt="Reverse sort order of users by last name"></button>
          </b-tooltip>
        </label>
        <select class="form-select" v-model="selectVal" size="4" style="height: 200px">
          <option v-for="user in filteredUsersNotInGroup" :key="user.idUser" :value="user.idUser">{{ user.firstName }}
            {{ user.lastName }}
          </option>
        </select>
      </div>
      <div class="col-1">
        <div>
          <p>Add</p>
          <button class="bi bi-caret-right-square" @click="addUserToGroup(selectVal)"
                  alt="Add selected user to group"></button>
        </div>
        <div>
          <button class="bi bi-caret-left-square" @click="removeUserFromGroup(selectVal)"
                  alt="Remove selected user from group"></button>
          <p>Remove</p>
        </div>
      </div>

      <div class="col">
        <label>Users In group
          <b-tooltip target="_self" title="Sort by Last Name">
            <button @click="reverseInGroup" :class="inGroupReversed ? 'bi bi-sort-up' : 'bi bi-sort-down'"
                    alt="Reverse sort order of users by last name"></button>
          </b-tooltip>
        </label>
        <select class="form-select" v-model="selectVal" size="4" style="height: 200px;">
          <option v-for="user in filteredUsersInGroup" :key="user.idUser" :value="user.idUser">{{ user.firstName }}
            {{ user.lastName }}
          </option>
        </select>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row" style="text-align: right">
      <div class="col-2"></div>
      <div class="col"></div>
      <div class="col">
        <button class="button btn-primary" @click="submitHelper" :disabled="!canSubmit">Save</button>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import {createGroup, getGroupById, updateGroup} from "@/views/SuperUserViews/UserGroups/GroupApiRoutes";
import Swal from "sweetalert2";
import router from "@/router";
import JobTypeDropdown from "@/components/JobType/JobTypeDropdown.vue";
import {isDistrictAdmin, isEsuUser, isInstructor, isSuperUser} from "@/RoleCheck";

export default {
  name: "UserGroupFormV2",
  components: {JobTypeDropdown},
  props: {
    idGroup: {
      type: Number,
      required: false,
    },
    editingExisting: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const authUser = store.getters['auth/user'];
    const groupData = ref({});
    const userList = ref([]);
    const usersInGroup = ref([]);
    const usersNotInGroup = ref([]);
    const selectedRoleType = ref(-1);
    const inGroupFilter = ref("");
    const notInGroupFilter = ref("");
    const usersToAdd = ref([]);
    const usersToRemove = ref([]);
    const notInGroupReversed = ref(false);
    const inGroupReversed = ref(false);
    const selectVal = ref();

    onBeforeMount(() => {
      getScopedUsers();
    })

    async function getScopedUsers() {
      let get_uri = API_URL + "/user/scopedPersonnel";
      await axios.get(get_uri, {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        userList.value = result.data;
      }).finally(() => {
        getGroup();
      })
    }

    async function getGroup() {
      await axios.get(getGroupById(), {
        params: {
          "_idGroup": props.idGroup,
        },
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        groupData.value = result.data;
        usersInGroup.value = result.data.groupedUsers.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
      }).finally(() => {
        usersNotInGroup.value = userList.value.filter((u) => !usersInGroup.value.find((({idUser}) => u.idUser === idUser)));
      })
    }

    function addUserToGroup(idUser) {
      usersToAdd.value.push(idUser);
      usersInGroup.value.push(usersNotInGroup.value.find(i => i.idUser === idUser));
      usersToRemove.value = usersToRemove.value.filter(val => val !== idUser);
      usersNotInGroup.value = usersNotInGroup.value.filter(i => i.idUser !== idUser);
    }

    function removeUserFromGroup(idUser) {
      usersToRemove.value.push(idUser);
      usersNotInGroup.value.push(usersInGroup.value.find(i => i.idUser === idUser));
      usersToAdd.value = usersToAdd.value.filter(val => val !== idUser);
      usersInGroup.value = usersInGroup.value.filter(i => i.idUser !== idUser);
    }

    function changeRoleFilter(value) {
      selectedRoleType.value = value;
    }

    function reverseInGroup() {
      usersInGroup.value.reverse();
      inGroupReversed.value = !inGroupReversed.value;
    }

    function reverseNotInGroup() {
      usersNotInGroup.value.reverse();
      notInGroupReversed.value = !notInGroupReversed.value;
    }

    function submitHelper() {
      if (props.editingExisting) {
        submitEdit();
      } else {
        submitCreate();
      }
    }

    async function submitCreate() {
      let post_body = {
        groupName: groupData.value.groupName,
        addingUserIds: usersToAdd.value
      }

      await axios.post(createGroup(), post_body, {
        headers: {
          "Authorization": "Header " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: "Created Group",
            icon: "success"
          }).then(() => {
            router.push({name: 'ViewGroup', params: {idGroup: result.data}})
          })
        }
      })
    }

    async function submitEdit() {
      let put_body = {
        idGroup: props.idGroup,
        groupName: groupData.value.groupName,
        addingUserIds: usersToAdd.value,
        removingUserIds: usersToRemove.value
      }
      await axios.put(updateGroup(), put_body, {
        headers: {
          "Authorization": "Header " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: "Updated Group",
            icon: "success"
          }).then(() => {
            getScopedUsers();
          })
        }
      })
    }


    const roleFilteredList = computed(() => {
      if (parseInt(selectedRoleType.value) !== -1) {
        return usersNotInGroup.value.filter((user) => user.roleHierarchyId === parseInt(selectedRoleType.value));
      }
      return usersNotInGroup.value;
    })

    const canSubmit = computed(() => {
      return groupData.value.groupName !== "" && groupData.value.groupName !== null && groupData.value.groupName !== undefined
    })

    const showEsucc = computed(() => {
      return isSuperUser(authUser.position.roleHierarchy);
    })

    const showEsuAdmin = computed(() => {
      return isSuperUser(authUser.position.roleHierarchy) || isEsuUser(authUser.position.roleHierarchy);
    })

    const showDistrictAdmin = computed(() => {
      return showEsuAdmin.value || isDistrictAdmin(authUser.position.roleHierarchy);
    })

    const showInstructor = computed(() => {
      return showDistrictAdmin.value || isInstructor(authUser.position.roleHierarchy);
    })

    const showNde = computed(() => {
      return showEsucc.value;
    })

    const filteredUsersNotInGroup = computed(() => {
      return roleFilteredList.value.filter((user) => {
        const first_name = user.firstName.toLowerCase();
        const last_name = user.lastName.toLowerCase();
        const filter_query = notInGroupFilter.value.toLowerCase();
        return first_name.includes(filter_query) || last_name.includes(filter_query);
      })
    })

    const filteredUsersInGroup = computed(() => {
      return usersInGroup.value.filter((user) => {
        const first_name = user.firstName.toLowerCase();
        const last_name = user.lastName.toLowerCase();
        const filter_query = inGroupFilter.value.toLowerCase();
        return first_name.includes(filter_query) || last_name.includes(filter_query);
      })
    })

    return {
      groupData,
      usersToAdd,
      usersToRemove,
      usersInGroup,
      usersNotInGroup,
      roleFilteredList,
      inGroupFilter,
      notInGroupFilter,
      inGroupReversed,
      notInGroupReversed,
      selectedRoleType,
      canSubmit,
      showEsucc,
      showEsuAdmin,
      showDistrictAdmin,
      showInstructor,
      showNde,
      selectVal,
      filteredUsersNotInGroup,
      filteredUsersInGroup,
      reverseInGroup,
      reverseNotInGroup,
      changeRoleFilter,
      submitHelper,
      addUserToGroup,
      removeUserFromGroup,
    }
  },
};
</script>

<style scoped>
</style>