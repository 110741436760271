<template>
  <div>
    <UserGroupFormV2 :id-group="idGroup"
                     :editing-existing="true" />
<!--    <UserGroupFormTemplate-->
<!--      :id-group="idGroup"-->
<!--      :editing-existing="true"-->
<!--      :title="'Edit'" />-->
  </div>
</template>

<script>
import UserGroupFormV2 from "@/views/SuperUserViews/UserGroups/UserGroupForm.vue";
export default {
  name: "UserGroupEdit",
  components: {UserGroupFormV2},
  props: {
    idGroup: Number
  },
  setup(props) {

  }
}
</script>

<style scoped>

</style>