<template>
  <select class="form-select" v-model="jobHierarchyId" id="jobHierarchyId" @change="changeRoleHierarchyId($event)"
          style="height: 41px;" :disabled="isDisabled" :tabindex="tabIdx">
    <option :value="-1">Select a Job Title</option>
    <option v-if="showEsucc" :value="1">ESUCC Staff</option>
    <option v-if="showEsuAdmin" :value="2">ESU Administrator</option>
    <option v-if="showDistrictAdmin" :value="3">District Administrator</option>
    <option v-if="showInstructor" :value="4">Supervisor</option>
    <option :value="5">Student (Para Course)</option>
    <option :value="6">Student (Supervisor Course)</option>
    <option v-if="showNde" :value="7">Nebraska Department of Education (NDE)</option>
  </select>
</template>

<script>
import {ref, onBeforeMount, watch} from "vue";

export default {
  name: "JobTypeDropdown",
  props: {
    showEsucc: Boolean,
    showEsuAdmin: Boolean,
    showDistrictAdmin: Boolean,
    showInstructor: Boolean,
    showNde: Boolean,
    jobId: Number,
    editingExisting: Boolean,
    isDisabled: Boolean,
    tabIdx: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  setup(props, context) {
    const jobHierarchyId = ref(-1);

    onBeforeMount(() => {
      if (props.editingExisting) {
        jobHierarchyId.value = props.jobId
      }
    })

    watch(() => props.jobId, (first, second) => {
      jobHierarchyId.value = props.jobId;
    })

    function changeRoleHierarchyId(event) {
      jobHierarchyId.value = event.target.value;
      context.emit("roleHierarchy", jobHierarchyId.value);
    }

    return {
      jobHierarchyId,
      changeRoleHierarchyId
    }
  }
}
</script>

<style scoped>

</style>